/**
 * Created by osirvent on 05/05/2017.
*/
angular
    .module('annexaApp')
    .constant('SecModals', {
        sessionNew: {
            title: 'global.sec.literals.newSession',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'organ',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.organ',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    onSelected: function(item, options) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('newSessionOrganSelected', { organ: item });
                                    }
                                },
                                controller: ['$scope', 'SecFactory', 'Language', '$rootScope', 'GlobalDataFactory', function ($scope, SecFactory, Language, $rootScope, GlobalDataFactory) {
                                    $scope.options.templateOptions.labelProp = Language.getActiveColumn();
                                    $scope.options.templateOptions.options = $linq(GlobalDataFactory.organs).where(function(x){
	                                	if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles && x.active){
                                    		var intersectedProfiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile && y.profile && x.profile.id && y.profile.id && x.profile.id == y.profile.id").toArray();
                                    		if(intersectedProfiles && intersectedProfiles.length > 0){
                                    			return true;
                                    		}else{
                                    			return false;
                                    		}
                                    	}else{
                                    		return false;
                                    	}
                                    }).toArray();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'sessionType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'GlobalDataFactory', 'Language', function ($scope, GlobalDataFactory, Language) {
                                    $scope.options.templateOptions.labelProp = Language.getActiveColumn();
                                    $scope.options.templateOptions.options = GlobalDataFactory.sessionTypes;
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'sessionFirstDate',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.sec.literals.sessionFirstConvenDate',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'sessionFirstTime',
                                type: 'annexaTimepickerRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    label: 'global.sec.literals.sessionFirstConvenHour',
                                    required: true,
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row3',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'sessionSecondDate',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.sec.literals.sessionSecondConvenDate',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'sessionSecondTime',
                                type: 'annexaTimepickerRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    label: 'global.sec.literals.sessionSecondConvenHour',
                                    required: true,
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row4',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'meetingLocation',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.meetingLocation',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'GlobalDataFactory', 'Language', function ($scope, GlobalDataFactory, Language) {
                                    $scope.options.templateOptions.labelProp = Language.getActiveColumn();
                                    $scope.options.templateOptions.options = GlobalDataFactory.meetingLocations;
                                    $scope.$on('newSessionOrganSelected', function(event, args){
                                    	if(args && args.organ && args.organ.meetingLocation){
                                    		$scope.model.meetingLocation = args.organ.meetingLocation;
                                    	}
                                    });
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        sessionPointNew: {
            title: 'global.sec.literals.newPoint',
            size: 'modal-lg',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'type',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.pointType',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                controller: ['$scope', 'Language', 'SecFactory', function ($scope, Language, SecFactory) {
                                    $scope.options.templateOptions.labelProp = Language.getActiveColumn();
                                    if($scope.options.templateOptions.isGroup){
                                    	$scope.options.templateOptions.options = $linq(SecFactory.session.organ.agendaPointTypes).where("x => x.type == 'GROUP'").toArray();
                                    	$scope.options.templateOptions.label = 'global.sec.literals.groupType';
                                    }else if($scope.options.templateOptions.isGroup == false){
                                    	$scope.options.templateOptions.options = $linq(SecFactory.session.organ.agendaPointTypes).where("x => x.type != 'GROUP'").toArray();
                                    }else{
                                    	$scope.options.templateOptions.options = SecFactory.session.organ.agendaPointTypes;
                                    }
                                    if($scope.options.templateOptions.options && $scope.options.templateOptions.options.length == 1){
                                    	$scope.model.type = $scope.options.templateOptions.options[0].id;
                                    }
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'proposals',
                                type: 'annexaComponent',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'annexa-session-points',
                                },
                                data: {
                                    type: undefined,
                                    proposals: [],
                                    pointData: "",
                                    pointObservations: "",
                                    documents: [],
                                    canEdit:true,
                                    votationType: "",
                                	requiredVoteState:"",
									fromAdmin:false,
									proposalsContainer:false,
									containerOrder:"",
									delegationType: undefined,
									weightedVote:false
                                }
                            }
                        ],
                        hideExpression: function($viewValue, $modelValue, scope) {
                            if(!scope.model.row1.hasOwnProperty('type')) {
                                return true;
                            } else if(!scope.model.row1.type) {
                                return true;
                            } else {
                                var SecFactory = angular.element(document.body).injector().get('SecFactory');

                                var pointType = $linq(SecFactory.organAgendaPointTypes).singleOrDefault(undefined, "x => x.id == " + scope.model.row1.type);

                                scope.fields[1].fieldGroup[0].data.type = pointType;

                                return false;
                            }
                        },
                        wrapper: 'annexaRow'
                    },
                ]
            }
        },
        sessionAttendeeNew: {
            title: 'global.sec.literals.newAttendee',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'attendee',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.sec.literals.attendee',
                                    required: true,
                                    focus: true,
                                    maxlength: 1024
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'memberPosition',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                	type: 'text',
                                	label: 'global.literals.position',
                                    required: false,
                                    focus: false,
                                    maxlength: 1024
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: []
        },
        sessionAttendeeEdit: {
            title: 'global.sec.literals.editAttendee',
            size: 'modal-sm',
            data: undefined,
            content: [
            	'<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.sec.literals.attendee" model-value="modal.data.attendee" model-property="attendee" save="modal.updateAttendee(val, prop)" required="true" blur="ignore" maxlength="1024" is-read-only="{{!modal.isMember && modal.canEdit}}"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="text" label="global.literals.position" model-value="modal.data.memberPosition" model-property="memberPosition" save="modal.updateAttendee(val, prop)" required="false" blur="ignore" maxlength="1024" is-read-only="{{!modal.isMember && modal.canEdit}}"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '		<annexa-editable-field type="select" label="global.literals.rol" model-value="modal.data.memberRol" model-property="memberRol" save="modal.updateAttendee(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumn" select-options="modal.organMemberRoles" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ]
        },
        sessionPointEdit: {
            title: 'global.sec.literals.editPoint',
            size: 'modal-lg',
            data: undefined,
            content: [
                '<div class="row p-t-xs p-b-sm">',
                '   <div class="col-lg-2">',
                '       <label ng-if="modal.data.type.type != \'GROUP\'" class="small m-b-xs" translate="global.sec.literals.pointType"></label>',
                '       <label ng-if="modal.data.type.type == \'GROUP\'" class="small m-b-xs" translate="global.sec.literals.groupType"></label>',
                '   </div>',
                '   <div class="col-lg-10 label-strong">',
                '		{{modal.data.type[modal.languageColumn]}}',
                '   </div>',
                '</div>',
                '<div class="row p-b-sm" ng-if="modal.data.proposal">',
                '   <div class="col-lg-4">',
                '		<div class="row">',
                '			<a href="" tabindex="0" target="_blank" ui-sref="annexa.sec.proposals.edit({proposal:modal.data.proposal.id})" class="text-info linkNoStyle tresPuntets">',
                '   			<div class="col-lg-12">',
                '	        		<label class="small m-b-xs" translate="global.sec.literals.proposal"</label>',
                '  			 	</div>',
                '   			<div class="col-lg-12 label-strong p-l text-primary">',
                '	        		{{modal.data.proposal.proposalNumber}}',
                '  			 	</div>',
                '	 			<div class="col-lg-12 label-strong p-l text-primary">',
                '					{{modal.data.proposal.extract}}',
                '		   		</div>',
                '      		</a>',
                '       </div>',
                '   </div>',
                '   <div class="col-lg-4">',
                '		<div class="row">',
                '  			<div class="col-lg-12">',
                '        		<label class="small m-b-xs" translate="global.literals.origin"</label>',
                '		 	</div>',
                '  			<div class="col-lg-12 label-strong p-l">',
                '        		{{modal.data.proposal.profile[modal.languageColumn]}}',
                '		 	</div>',
                ' 			<div class="col-lg-12 label-strong p-l">',
                '				{{modal.data.proposal.senderUser.name + " " + modal.data.proposal.senderUser.surename1 + (modal.data.proposal.senderUser.surename2 ? " " + modal.data.proposal.senderUser.surename2 : "")}}',
                '	   		</div>',
                '       </div>',
                '   </div>',
                '   <div class="col-lg-4">',
                '		<div class="row" ng-if="modal.data.proposal.createdTransaction && modal.data.proposal.createdTransaction.dossier">',
                '			<a href="" ng-if="modal.data.proposal.createdTransaction.dossier.dossierStatus != \'ARCHIVED\' && modal.data.proposal.createdTransaction.dossier.dossierStatus != \'REJECTED\'  && modal.data.proposal.createdTransaction.dossier.dossierStatus != \'TRANSFER\'" tabindex="0" target="_blank" ui-sref="annexa.tram.pending.viewdossier({ dossier: modal.data.proposal.createdTransaction.dossier.id,  dossierTransaction: modal.data.proposal.createdTransaction.id})" class="text-info linkNoStyle tresPuntets">',
                '   			<div class="col-lg-12">',
                '	        		<label class="small m-b-xs" translate="global.literals.dossier"</label>',
                '  			 	</div>',
                '   			<div class="col-lg-12 label-strong p-l text-primary" ng-if="modal.data.proposal.createdTransaction && modal.data.proposal.createdTransaction.dossier && modal.data.proposal.createdTransaction.dossier.dossierNumber">',
                '	        		{{modal.data.proposal.createdTransaction.dossier.dossierNumber}}',
                '  			 	</div>',
                '	 			<div class="col-lg-12 label-strong p-l text-primary" ng-if="modal.data.proposal.createdTransaction && modal.data.proposal.createdTransaction.dossier && modal.data.proposal.createdTransaction.dossier.subject">',
                '					{{modal.data.proposal.createdTransaction.dossier.subject}}',
                '		   		</div>',
                '      		</a>',
                '			<span ng-if="!(modal.data.proposal.createdTransaction.dossier.dossierStatus != \'ARCHIVED\' && modal.data.proposal.createdTransaction.dossier.dossierStatus != \'REJECTED\'  && modal.data.proposal.createdTransaction.dossier.dossierStatus != \'TRANSFER\')" tabindex="0" class="text-info linkNoStyle tresPuntets">',
                '   			<div class="col-lg-12">',
                '	        		<label class="small m-b-xs" translate="global.literals.dossier"</label>',
                '  			 	</div>',
                '   			<div class="col-lg-12 label-strong p-l text-primary" ng-if="modal.data.proposal.createdTransaction && modal.data.proposal.createdTransaction.dossier && modal.data.proposal.createdTransaction.dossier.dossierNumber">',
                '	        		{{modal.data.proposal.createdTransaction.dossier.dossierNumber}}',
                '  			 	</div>',
                '	 			<div class="col-lg-12 label-strong p-l text-primary" ng-if="modal.data.proposal.createdTransaction && modal.data.proposal.createdTransaction.dossier && modal.data.proposal.createdTransaction.dossier.subject">',
                '					{{modal.data.proposal.createdTransaction.dossier.subject}}',
                '		   		</div>',
                '      		</span>',
                '       </div>',
                '   </div>',
                '</div>',
                '<div class="row">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field ng-if="modal.data.type.type != \'GROUP\'" type="textarea" label="global.sec.literals.pointTitle" model-value="modal.data.title" model-property="title" save="modal.updatePoint(val, prop)" required="true" blur="igonre" rows="3" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '       <annexa-editable-field ng-if="modal.data.type.type == \'GROUP\'" type="textarea" label="global.sec.literals.groupTitle" model-value="modal.data.title" model-property="title" save="modal.updatePoint(val, prop)" required="true" blur="igonre" rows="3" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row" ng-if="modal.showVoteType">',
                '   <div class="col-lg-12">',
		        '		<annexa-editable-field type="select" label="global.sec.literals.votationType" model-value="modal.votationType" model-property="votationType" save="modal.updatePoint(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumnEnum" select-options="modal.votationTypes" print-function="modal.printEnum"  is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
		        '   </div>',
		        '</div>',
                '<div class="row" ng-if="modal.showVoteType">',
                '   <div class="col-lg-12">',
		        '		<annexa-editable-field type="select" label="global.sec.literals.resultType" model-value="modal.requiredVoteState" model-property="requiredVoteState" save="modal.updatePoint(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumnEnum" select-options="modal.requiredVoteStates" print-function="modal.printEnum"  is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
		        '   </div>',
		        '</div>',
                '<div class="row">',
                '   <div class="col-lg-12" >',
                '       <annexa-editable-field type="textarea" label="global.literals.observations" model-value="modal.data.observations" model-property="observations" save="modal.updatePoint(val, prop)" required="false" blur="igonre" rows="5" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '   </div>',
                '</div>',
                ' <div class="row" ng-if="modal.data.proposal.reviewObservations || modal.data.proposal.validateObservations || modal.data.proposal.acceptObservations">',
                '	<div class="col-lg-8 col-sm-8 m-b-sm">',
                '		<button type="button" class="btn primary text-white" ng-click="modal.validationCircuitObservations()">',
                '			<i class="fa fa-eye" aria-hidden="true"></i>',
                '			<span translate="global.literals.validationCircuitObservations" class="ng-scope">Validation Circuit Observations</span>',
                '			<span ng-if="modal.data.proposal.reviewDisagreement || modal.data.proposal.validateDisagreement || modal.data.proposal.acceptDisagreement" class="ng-scope"> (</span>',
                '			<span ng-if="modal.data.proposal.reviewDisagreement || modal.data.proposal.validateDisagreement || modal.data.proposal.acceptDisagreement" translate="global.literals.withDisagreement" class="ng-scope"> (With disagreement)</span>',
                '			<span ng-if="modal.data.proposal.reviewDisagreement || modal.data.proposal.validateDisagreement || modal.data.proposal.acceptDisagreement" class="ng-scope">)</span>',
                '		</button>',
                '	</div>',
                '</div>',
                '<div class="row" ng-if="modal.data.proposal">',
                '    <div class="col-sm-12">',
		        '        <label class="small m-b-xs _700"><span translate="global.sec.literals.proposalDocuments">Documents de la proposta</span></label>',
		        '    </div>',
		        '</div>',
                '<div class="row" ng-if="modal.data.proposal">',
                '    <div class="col-sm-12">',
		        '        <annexa-box-object-session-orders-documents documents="modal.data.proposal.documents" module="SEC" can-edit="false"></annexa-box-object-session-orders-documents>',
		        '    </div>',
		        '</div>',
                '<div class="row" ng-if="modal.data.type.type != \'GROUP\'">',
                '    <div class="col-sm-12">',
		        '        <label class="small m-b-xs _700"><span translate="global.literals.documents">Documents</span></label>',
		        '    </div>',
		        '</div>',
                '<div class="row" ng-if="modal.data.type.type != \'GROUP\'">',
                '    <div class="col-sm-12">',
		        '        <annexa-box-object-session-orders-documents documents="modal.data.documents" module="SEC" save-documents="modal.updatePoint" model="modal.data" can-edit="modal.canEdit"></annexa-box-object-session-orders-documents>',
		        '    </div>',
                '   <uib-tabset type="pills" id="view-dossier-principal" class="col-lg-12 primary-pills" ng-if="modal.showVote" active="modal.extra.activeTab">',
                '       <div class="p-x-sm">',
                '           <uib-tab heading="{{\'global.sec.literals.vote2\' | translate}}" class="col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal" ng-show="modal.showVote">',
                '               <div class="col-lg-12">',
                '                   <annexa-session-vote session-order="modal.data" max-votes="modal.extra.maxVotes" type="ORDINARY" can-edit="modal.canEdit" votation-type="modal.data.votationType"></annexa-session-vote>',
                '               </div>',
                '           </uib-tab>',
                '           <uib-tab heading="{{\'global.sec.literals.resultVote\' | translate}}" class="col-md-6 col-lg-3 col-xxl-3 tab tabPrincipal" ng-show="modal.canModifyResult && modal.showVote">',
                '   			<div class="col-lg-6" ng-if="modal.canModifyResult && modal.showVote">',
		        '					<annexa-editable-field type="select" label="global.sec.literals.resultVote" model-value="modal.voteState" model-property="voteState" required="true" blur="ignore" lang-col="modal.languageColumnEnum" select-options="modal.requiredVoteStatesModify" print-function="modal.printEnum"  is-read-only="false"></annexa-editable-field>',
		        '   			</div>',
		        '   			<div class="col-lg-6" ng-if="modal.canModifyResult && modal.showVote">',
		        '					<button type="button" ng-if="!modal.data.voteStateUpdated && modal.canEdit" class="btn btn-sm primary" ng-click="modal.changeVoteState();"><span translate="global.sec.literals.changeVoteState">Change vote state</span></button>',
		        '					<button type="button" ng-if="modal.data.voteStateUpdated && modal.canEdit" class="btn btn-sm primary" ng-click="modal.deleteVoteStateUpdate();"><span translate="global.sec.literals.deleteVoteState">Delete vote state</span></button>',
		        '   			</div>',
		        '   			<div class="col-lg-12" ng-if="modal.data.voteStateUpdated && modal.canModifyResult && modal.showVote"">',
		        '					<annexa-editable-field type="select" label="global.sec.literals.voteStateUpdated" model-value="modal.voteStateUpdated" model-property="voteStateUpdated" save="modal.updatePoint(val, prop)" required="true" blur="ignore" lang-col="modal.languageColumnEnum" select-options="modal.requiredVoteStatesModify" print-function="modal.printEnum"  is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
		        '   			</div>',
		        '   			<div class="col-lg-12" ng-if="modal.data.voteStateUpdated && modal.canModifyResult && modal.showVote"">',
                '       			<annexa-editable-field type="textarea" label="global.sec.literals.changeMotive" model-value="modal.data.changeStateMotive" model-property="changeStateMotive" save="modal.updatePoint(val, prop)" required="true" blur="igonre" rows="3" is-read-only="{{modal.canEdit}}"></annexa-editable-field>',
                '   			</div>',
	            '           </uib-tab>',
                '       </div>',
                '   </uib-tabset>',
                '</div>'
            ]
        },
        amendmentProposal: {
            title: 'global.sec.literals.amendmentPopupProposal',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'reason',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: true
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        cancelProposal: {
            title: 'global.sec.literals.cancelPopupProposal',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'reason',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: true
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        actionWithObservationProposal: {
            title: 'global.sec.literals.cancelPopupProposal',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'disagreement',
                                type: 'annexaCheckbox',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed: true,
                                    row: true
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    required: false,
                                    validate: true,
                                    label: 'global.literals.withDisagreement'
                                }
                            },
                            {
                                key: 'reason',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.observations',
                                    required: false,
                                    focus: true
                                }
                            },
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        retireSessionPoint: {
            title: 'global.sec.literals.retirePoint',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'reason',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: true
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        initTransfers: {
            title: 'global.sec.literals.sendTransfer',
            size: 'modal-lg',
            data: undefined,
            content: [
            	'<div class="container-fluid m-b-lg grey-100 p-y-sm">',
	        	'	<div class="row b-b m-b">',
	        	'        <div class="col-sm-1">',
	            '        </div>',
	            '        <div class="col-sm-1">',
	            '            <label class="small m-b-xs _700"><span translate="global.literals.state">State</span></label>',
	            '        </div>',
	            '        <div class="col-sm-3">',
	            '            <label class="small m-b-xs _700"><span translate="global.sec.literals.proposalNumber">Proposal Number</span></label>',
	            '        </div>',
	            '        <div class="col-sm-4" >',
	            '            <label class="small m-b-xs _700"><span translate="global.literals.title">Title</span></label>',
	            '        </div>',
	            '        <div class="col-sm-3">',
	            '            <label class="small m-b-xs _700"><span translate="global.literals.dossier">Dossier</span></label>',
	            '        </div>',
	        	'	</div>',
	        	'	<div class="row b-b m-b p-b" ng-if="modal.data.proposals && modal.data.proposals.length != 0" ng-repeat="proposal in modal.data.proposals">',
	        	'		 <div class="col-sm-1">',
                '   		<div class="text-center">',
                '				<input type="checkbox" ng-model="proposal.transferNow" class="m-r-0"></input>',
                '			</div>',
                '		 </div>',
				'        <div class="col-sm-1">',
	        	'			<div class="text-center">',
                '    			<span class="fa-stack" title="{{modal.getTitle(proposal)}}">',
                '        			<i class="fa fa-{{modal.getIcon(proposal)}} text-lg fa-stack-1x"></i>',
                '    			</span>',
                '			</div>',
	            '        </div>',
	            '        <div class="col-sm-3">',
	            '            <div>{{proposal.proposalNumber}}</div>',
	            '        </div>',
	            '        <div class="col-sm-4" >',
	            '           <div>{{proposal.extract}}</div>',
	            '        </div>',
	            '        <div class="col-sm-3">',
	            '            <div>{{modal.getDossier(proposal)}}</div>',
	            '        </div>',
	            '	</div>',
        		'</div>'
            ]
        },
        raiseOpinion: {
            title: 'global.sec.literals.sendOpinion',
            size: 'modal-lg',
            data: undefined,
            content: [
            	'<div class="container-fluid m-b-lg grey-100 p-y-sm">',
	        	'	<div class="row b-b m-b">',
	        	'        <div class="col-sm-1">',
	            '            <label class="small m-b-xs _700"><span translate="global.literals.state">State</span></label>',
	            '        </div>',
	            '        <div class="col-sm-2">',
	            '            <label class="small m-b-xs _700"><span translate="global.sec.literals.proposalNumber">Proposal Number</span></label>',
	            '        </div>',
	            '        <div class="col-sm-5" >',
	            '            <label class="small m-b-xs _700"><span translate="global.literals.title">Title</span></label>',
	            '        </div>',
	            '        <div class="col-sm-4">',
	            '            <label class="small m-b-xs _700"><span translate="global.literals.actions">Actions</span></label>',
	            '        </div>',
	        	'	</div>',
	        	'	<div class="row b-b m-b p-b" ng-if="modal.data.proposals && modal.data.proposals.length != 0" ng-repeat="proposal in modal.data.proposals">',
	        	'        <div class="col-sm-1">',
	        	'			<div class="text-center">',
                '    			<span class="fa-stack" title="{{modal.getTitle(proposal)}}">',
                '        			<i class="fa fa-{{modal.getIcon(proposal)}} text-lg fa-stack-1x"></i>',
                '    			</span>',
                '			</div>',
	            '        </div>',
	            '        <div class="col-sm-2">',
	            '            <div>{{proposal.proposalNumber}}</div>',
	            '        </div>',
	            '        <div class="col-sm-5" >',
	            '           <div>{{proposal.extract}}</div>',
	            '        </div>',
	            '        <div class="col-sm-4">',
				'            <div>',
				'	            <ui-select fix-focus-on-touch ng-model="proposal.actionToDo" theme="bootstrap">',
				'                    <ui-select-match>',
				'                        {{proposal.actionToDo.name | translate}}',
				'                    </ui-select-match>',
				'                    <ui-select-choices repeat="item in proposal.actionsToDo| filter: $select.search">',
				'                        <div>',
				'                            {{item.name | translate}}',
				'                        </div>',
				'                    </ui-select-choices>',
				'                </ui-select>',
			    '            </div>',
	            '        </div>',
	            '	</div>',
        		'</div>'
            ]
        },
        startSession:{
            title: 'global.sec.literals.startSession',
            size: 'modal-md',
            icon: 'fa fa-play',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: '',
                        fieldGroup: [
                        	{
                                key: 'startSessionDate',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.sec.literals.realStartSessionDateDate',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'startSessionTime',
                                type: 'annexaTimepickerRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    label: 'global.sec.literals.realStartSessionDateHour',
                                    required: true,
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        finishSession:{
            title: 'global.sec.literals.endSession',
            size: 'modal-md',
            icon: 'fa fa-stop',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: '',
                        fieldGroup: [
                        	{
                                key: 'finishSessionDate',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.sec.literals.realFinishSessionDateDate',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'finishSessionTime',
                                type: 'annexaTimepickerRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    label: 'global.sec.literals.realFinishSessionDateHour',
                                    required: true,
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        sessionChangeVoteState: {
            title: 'global.sec.literals.changeVoteState',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                        	{
                                key: 'voteStateUpdated',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.sec.literals.voteStateUpdated',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: true
                                },
                                controller: ['$scope', 'SecFactory', 'Language', function ($scope, SecFactory, Language) {
                                    $scope.options.templateOptions.options = SecFactory.requiredVoteStatesModify;
                                    if($scope.options.templateOptions.options && $scope.options.templateOptions.options.length > 0){
                                    	$scope.model.voteStateUpdated = $linq($scope.options.templateOptions.options).firstOrDefault(undefined, "x => x.id == '"+(($scope.model.voteStateUpdatedAux == 'APPROVED')?'NOT_APPROVED':'APPROVED')+"'");
                                	}
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'changeStateMotive',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.sec.literals.changeMotive',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: []
        },
        updateCertificates: {
            title: 'global.sec.literals.updateCertificates',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'selectAll',
                                type: 'annexaSelectAllRow',
                                className: '',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAll',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.sessionExecutedOrders){
                                            var keys = Object.keys($scope.model.sessionExecutedOrders);
                                            _.forEach(keys, function(key){
                                                if($scope.model.selectAll){
                                                    $scope.model.sessionExecutedOrders[key] = true;
                                                }else{
                                                    $scope.model.sessionExecutedOrders[key] = false;
                                                }
                                            });
                                        }
                                    };
                                }]
                            },
                            {
                                key: 'sessionExecutedOrders',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed: true,
                                    row: true
                                },
                                templateOptions: {
                                    type: 'checkHTMLOneModel',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: false,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'value'
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model.sessionExecutedOrders',
                                    listener: function(field, _new, _old, scope) {
                                        var keys = Object.keys(_new);
                                        var isAllTrue = true;
                                        if(keys && keys.length > 0) {
                                            _.forEach(keys, function(key){
                                                isAllTrue = isAllTrue && (_new[key])?true : false;
                                            });
                                        }else{
                                            isAllTrue = false;
                                        }
                                        scope.model.selectAll = isAllTrue;
                                    }
                                },
                                controller: ['$scope','$rootScope', '$filter',function ($scope, $rootScope, $filter) {
                                    var options = [];
                                    if($scope.model.sessionExecutedOrdersAux && $scope.model.sessionExecutedOrdersAux.length > 0){
                                        _.forEach($scope.model.sessionExecutedOrdersAux, function(value){
                                            options.push({id:value.id, value:value.title});
                                        })

                                    }
                                    $scope.to.options = options;
                                }]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        globalVoteModal: {
            title: 'global.sec.literals.globalVote',
            size: 'modal-lg',
            data: undefined,
            content: [
            	'<fieldset class="fieldsetSec" ng-if="modal.executedOrderSec && modal.executedOrderSec.length > 1">',
            	'	<legend class="fieldsetSec__title _600">{{\'global.sec.literals.votePointsSec\' | translate}}</legend>',
	            '	<div class="col-lg-12"><ul>',
		        '		<li ng-repeat="executedOrder in modal.executedOrderSec">',
		        '			<span>{{executedOrder.title}}</span>',
		        '			<span ng-if="executedOrder.voteState == \'NO_VOTE\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-danger" ng-if="executedOrder.voteState == \'NOT_APPROVED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-success" ng-if="executedOrder.voteState == \'APPROVED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-grey" ng-if="executedOrder.voteState == \'REFUSED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-grey" ng-if="executedOrder.voteState == \'POSTPONED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '		</li>',
		        '	</ul></div>',
                '	<div class="row m-b-sm" ng-if="modal.executedOrderSec && modal.executedOrderSec.length > 1">',
            	'		<div class="col-lg-12">',
                '			<annexa-session-vote session-order="modal.data" max-votes="modal.extra.maxVotes" type="ORDINARY" can-edit="modal.canEdit" votation-type="modal.data.votationType"></annexa-session-vote>',
                '		</div>',
                '	</div>',
                '	<div class="pull-right">',
                '		<button class="btn btn-sm primary" ng-click="modal.calcResult(\'SECRET\')">{{\'global.sec.literals.calculateResult\' | translate}}</button>',
                '	</div>',
                '</fieldset>',
            	'<fieldset class="fieldsetNom" ng-if="modal.executedOrderNom && modal.executedOrderNom.length > 1">',
            	'	<legend class="fieldsetNom__title _600">{{\'global.sec.literals.votePointsNominal\' | translate}}</legend>',
	            '	<div class="col-lg-12"><ul>',
		        '		<li ng-repeat="executedOrder in modal.executedOrderNom">',
		        '			<span>{{executedOrder.title}}</span>',
		        '			<span ng-if="executedOrder.voteState == \'NO_VOTE\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-danger" ng-if="executedOrder.voteState == \'NOT_APPROVED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-success" ng-if="executedOrder.voteState == \'APPROVED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-grey" ng-if="executedOrder.voteState == \'REFUSED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-grey" ng-if="executedOrder.voteState == \'POSTPONED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '		</li>',
		        '	</ul></div>',
                '	<div class="row m-b-sm" ng-if="modal.executedOrderNom && modal.executedOrderNom.length > 1">',
            	'		<div class="col-lg-12">',
                '			<annexa-session-vote session-order="modal.executedOrderVotes" max-votes="modal.extra.maxVotes" type="ORDINARY" can-edit="modal.canEdit" votation-type="\'NOMINAL\'"></annexa-session-vote>',
                '		</div>',
                '	</div>',
                '	<div class="pull-right">',
                '		<button class="btn btn-sm primary" ng-click="modal.calcResult(\'NOMINAL\')">{{\'global.sec.literals.calculateResult\' | translate}}</button>',
                '	</div>',
                '</fieldset>',
                '<fieldset class="fieldsetWeight" ng-if="modal.executedOrderWeight && modal.executedOrderWeight.length > 1">',
            	'	<legend class="fieldsetNom__title _600">{{\'global.sec.literals.votePointsWeight\' | translate}}</legend>',
	            '	<div class="col-lg-12"><ul>',
		        '		<li ng-repeat="executedOrder in modal.executedOrderWeight">',
		        '			<span>{{executedOrder.title}}</span>',
		        '			<span ng-if="executedOrder.voteState == \'NO_VOTE\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-danger" ng-if="executedOrder.voteState == \'NOT_APPROVED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-success" ng-if="executedOrder.voteState == \'APPROVED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-grey" ng-if="executedOrder.voteState == \'REFUSED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '			<span class="text-grey" ng-if="executedOrder.voteState == \'POSTPONED\'"> / {{\'global.sec.literals.\' + executedOrder.voteState | translate}}</span>',
		        '		</li>',
		        '	</ul></div>',
                '	<div class="row m-b-sm" ng-if="modal.executedOrderWeight && modal.executedOrderWeight.length > 1">',
            	'		<div class="col-lg-12">',
                '			<annexa-session-vote session-order="modal.executedOrderVotesWeight" max-votes="modal.extra.maxVotes" type="ORDINARY" can-edit="modal.canEdit" votation-type="\'WEIGHTED\'"></annexa-session-vote>',
                '		</div>',
                '	</div>',
                '	<div class="pull-right">',
                '		<button class="btn btn-sm primary" ng-click="modal.calcResult(\'WEIGHTED\')">{{\'global.sec.literals.calculateResult\' | translate}}</button>',
                '	</div>',
                '</fieldset>'
            ]
        },
        selectProposalToAddDocument: {
            title: 'global.sec.literals.addToProposal',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
								 key: 'addProposal',
								 type: 'annexaRadioCheckboxRow',
								 className: 'col-sm-12 daughter-label-strong',
								 data: {
								     informed: true,
								     row: true
								 },
								 templateOptions: {
								     type: 'radio',
								     optionsAttr: 'bs-options',
								     required: true,
								     validate: true,
								     options:[],
								     ngOptions:'option[to.valueProp] as option in to.options',
								     valueProp: 'id',
								     labelProp: 'value',
								     label: 'global.sec.literals.addToProposalBody'
								 },
								 controller: function ($scope, $filter) {
								     $scope.to.options = [
								         { id:1, value: $filter("translate")("global.literals.yes") },
								         { id:2, value: $filter("translate")("global.literals.no") }
								     ];
								 }
                            },
                            {
								 key: 'proposalAdded',
								 type: 'annexaRadioCheckboxRow',
								 className: 'col-sm-12 daughter-label-strong',
								 data: {
								     informed: true,
								     row: true
								 },
								 templateOptions: {
								     type: 'radio',
								     optionsAttr: 'bs-options',
								     required: true,
								     validate: true,
								     options:[],
								     ngOptions:'option[to.valueProp] as option in to.options',
								     valueProp: 'id',
								     labelProp: 'value',
								     label: 'global.sec.proposals.title'
								 },
								 controller: function ($scope, $filter) {
									 $scope.to.options = [];
							         if($scope.model.proposals && $scope.model.proposals.length > 0){
							        	 _.forEach($scope.model.proposals, function(proposal){
							        		 $scope.to.options.push({ id:proposal.id, value: proposal.extract });
							        	 });
							         }
								 },
								 hideExpression: function($viewValue, $modelValue, scope) {
			                            if(scope.model.addProposal && scope.model.addProposal == 1) {
			                                return false;
			                            }
			                            return true;
			                        }
                           }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        }
    });